import React, { useEffect, useState, lazy, Suspense } from 'react'
import MiniNav from '../NavBar/MiniNav'
import LogoNav from '../NavBar/LogoNav'
import MainNav from '../NavBar/MainNav'
import 'animate.css';
import "../NavBar/nav.css"
import "../Component/home.css"
import HomeBanner from './HomeBanner';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Body1 from './Body1';
import Chooseus from './Chooseus';
import Body2Landing from './Body2Landing';
import ContactLanding from './ContactLanding';
import LandinBLog from './LandinBLog';
import Bottombar from '../NavBar/Bottombar';
import DarkNav from '../NavBar/DarkNav';
import { Helmet } from 'react-helmet';
const LandingPage = () => {
  let [anime, setanime] = useState("")
  useEffect(() => {
    setanime("animate__fadeIn");
    window.scrollTo(0, 0);
    const element = document.querySelector('.durationani');
    element.style.setProperty('--animate-duration', '4s');
  }, [])
  const LazyHomeBanner = lazy(() => import('./HomeBanner'));
  return (
    <section id="home" className={`${anime} animate__animated transi durationani `}>
      <Helmet>
        <title>The Best Software Services and Digital Marketing Company</title>
        <meta name="description" content="Achieve your business goal with our software solutions and digital marketing services to drive growth, Increase conversion and ROI with a brand in the industry." />
        <meta name='keywords' content='software solutions, digital marketing strategies, branding, consulting services' />
        <link rel="canonical" href="https://www.meridatechminds.com" />
      </Helmet>
      <h1 className='d-none'>software development company </h1>
      <h2 className='d-none'>digital marketing company</h2>

      <MiniNav />
      {/* <LogoNav/> */}
      <MainNav />
      <DarkNav />
      {/* <MainNavBar/> */}
      <Suspense fallback={<div>Loading...</div>}>
        <LazyHomeBanner />
      </Suspense>
      {/* <HomeBanner/> */}
      <Body1></Body1>
      <Chooseus />
      <Body2Landing />
      <LandinBLog />
      <ContactLanding />
      {/* <Bottombar/> */}
    </section>
  )
}

export default LandingPage